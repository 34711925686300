import React, { useState,useRef,useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Recycle from '../Components/Recycle'
import About from '../Components/About'
import Partner from '../Components/Partner'
import Strategy from '../Components/Strategy'
import DownLoadApp from '../Components/DownLoadApp'
import Social from '../Components/Social'
import Footer from '../Components/Footer'
import ContactModal from '../Components/ContactModal'
import SubscribeModal from '../Components/SubscribeModal'

export default function Home() {
  const [contactUs,setContactUs] = useState(false);
  const [subscribe,setSubscribe] = useState(false);
  // const sections = ['section1', 'section2', 'section3'];
  const [activeSection, setActiveSection] = useState('');
  // const sectionRefs = useRef({}); // Keep a reference to all the section elements
  const sectionRefs = useRef({});

  const sections = ["strategy", "partner", "download", "hero", "s", "f", "r"];
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        //console.log("entries", entries);
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log("id",entry.target.id)
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.8 }
    );

    sections.forEach((section) => {
      const sectionElement = document.getElementById(section);
      sectionRefs.current[section] = sectionElement;
      if (sectionElement) observer.observe(sectionElement);
    });

    return () => {
      sections.forEach((section) => {
        const sectionElement = sectionRefs.current[section];
        if (sectionElement) observer.unobserve(sectionElement);
      });
    };
  }, [sections]);

  // useEffect(() => {
  //   // Initialize IntersectionObserver
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           setActiveSection(entry.target.id); // Update the active section when it's in view
  //         }
  //       });
  //     },
  //     { threshold: 0.1 } // Adjust this value as needed, e.g., 0.5 means half the section is visible
  //   );

  //   // Observe each section
  //   sections.forEach((section) => {
  //     const sectionElement = document.getElementById(section);
  //     sectionRefs.current[section] = sectionElement;
  //     if (sectionElement) observer.observe(sectionElement);
  //   });

  //   // Clean up the observer when the component unmounts
  //   return () => {
  //     sections.forEach((section) => {
  //       const sectionElement = sectionRefs.current[section];
  //       if (sectionElement) observer.unobserve(sectionElement);
  //     });
  //   };
  // }, []);
  return (
    <div>
        <Navbar setContactUs={setContactUs} activeSection={activeSection}/>
       <Recycle />
       <About setContactUs={setContactUs}/>
       <Partner setSubscribe={setSubscribe}/>
       <Strategy />
       <DownLoadApp />
       <Social />
       <Footer />
      
       {
contactUs&&
       <ContactModal setContactUs={setContactUs}/>
       }
       {
        subscribe && <SubscribeModal setSubscribe={setSubscribe}/>
       }
    </div>
  )
}
