import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function Navbar({ setContactUs, activeSection }) {
  const [language, setLanguage] = useState(
    localStorage.getItem("lang")
      ? localStorage.getItem("lang") === "en"
        ? "ar"
        : "en"
      : "en"
  );
  const [path, setPath] = useState("");
  const [isScrolling, setIsScrolling] = useState(false);
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const { t, i18n } = useTranslation();
  const toggleDirection = (lng) => {
    const newDirection = lng === "ar" ? "rtl" : "ltr";

    document.documentElement.dir = newDirection;
    document.documentElement.lang = lng;
  };

  ///// useEffects

  useEffect(() => {
    setPath(activeSection);
  }, [activeSection]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang") || "en");
    toggleDirection(
      localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
    );
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  ///// handlers
  /// change language handler
  const onLanguageChange = (e) => {
    console.log(e.target.innerText);
    localStorage.setItem("lang", e.target.innerText);
    i18n.changeLanguage(e.target.innerText);
    setLanguage(e.target.innerText === "en" ? "ar" : "en");
    toggleDirection(e.target.innerText);
  };

  //////////

  return (
    <div
      className={`${
        isScrolling ? "shadow-md" : "shadow-none"
      } sticky top-0 bg-white z-[999]`}
    >
      <div className="container mx-auto py-2 px-2 sm:px-4  ">
        <div className="flex justify-between items-center">
          <img src="/images/orb-logo.png" className="h-[60px] md:h-[80px] " />
          {/* <div className="flex items-center"> */}
          <div className="hidden md:flex justify-center items-center  ">
            <a
              className={`text-[14px] lg:text-[18px] mx-[6px] lg:mx-2 font-[600] hover:cursor-pointer hover:text-[#209163] py-2 hover:border-b-[2px] border-b-[#209163] 
             ${ path === "hero"
                  ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                  : ""
              }`}
            >
              {t("home")}
            </a>
            <a
              href="#strategy"
              className={`text-[14px] lg:text-[18px] mx-[6px] lg:mx-2 font-[600] hover:cursor-pointer hover:text-[#209163] py-2 hover:border-b-[2px] border-b-[#209163] ${
                path === "strategy"
                  ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                  : ""
              }`}
              onClick={() => setPath("startegy")}
            >
              {t("work-strategy")}
            </a>
            <a
              href="#partner"
              className={`text-[14px] lg:text-[18px] mx-[6px] lg:mx-2 font-[600] hover:cursor-pointer hover:text-[#209163] py-2 hover:border-b-[2px] border-b-[#209163] ${
                path === "partner"
                  ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                  : ""
              }`}
              onClick={() => setPath("partner")}
            >
              {t("partner")}
            </a>
            <a
              href="#download"
              className={`text-[14px] lg:text-[18px] mx-[6px] lg:mx-2 font-[600] hover:cursor-pointer hover:text-[#209163] py-2 hover:border-b-[2px] border-b-[#209163] ${
                path === "download"
                  ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                  : ""
              }`}
              onClick={() => setPath("download")}
            >
              {t("have-our-app")}
            </a>
          </div>
          {/* </div> */}
          <div className="flex items-center">
            <img src="/images/lang-icon.png" className="w-5" />
            {/* <select
              className="ltr: ml-1 ltr:mr-5 rtl:ml-5 rtl:mr-1 cursor-pointer"
              onChange={onLanguageChange}
              defaultValue={language}
            >
              <option value="en">En</option>
              <option value="ar">Ar</option>
            </select> */}
            <button
              className="font-[500] text-[18px] ltr: ml-1 ltr:mr-5 rtl:ml-5 rtl:mr-1 cursor-pointer text-black"
              onClick={onLanguageChange}
            >
              {language}
            </button>
            <button
              className=" border-none rounded-[8px] bg-[#209163] min-h-[35px] md:min-h-[40px] text-[14px] md:text-[16px] font-[400] md:font-[600] w-fit py-1 px-3 md:w-[140px] text-white"
              onClick={() => setContactUs(true)}
            >
              {t("contact-us")}
            </button>
            <img
              src="/images/nav-icon.png"
              className="w-[30px] md:w-[40px] object-contain md:hidden ms-2 md:ms-4 cursor-pointer"
              onClick={() => setToggleNavbar(!toggleNavbar)}
            />
          </div>
        </div>
        {toggleNavbar && (
          <div className="py-4 md:hidden bg-gray-50 my-2">
            <a className="mx-2 font-[600] hover:cursor-pointer p-1 hover:bg-[#209163] hover:text-white mt-2 md:mt-0">
              {t("home")}
            </a>
            <a
              href="#strategy"
              className="mx-2 font-[600] hover:cursor-pointer p-1 hover:bg-[#209163] hover:text-white mt-2 md:mt-0"
            >
              {t("work-strategy")}
            </a>
            <a
              href="#partner"
              className="mx-2 font-[600] hover:cursor-pointer p-1 hover:bg-[#209163] hover:text-white mt-2 md:mt-0"
            >
              {t("partner")}
            </a>
            <a
              href="#download"
              className="mx-2 font-[600] hover:cursor-pointer p-1 hover:bg-[#209163] hover:text-white mt-2 md:mt-0"
            >
              {t("have-our-app")}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
